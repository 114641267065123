import {
  TeamItem,
  ImgWrapper,
  Img,
  Info,
  Name,
  Role,
  SubContainer
} from "@/page/company/teamStyle";
import { OUR_TEAM } from "@/constants/memberList";

const OurTeam = () => {
  const memberList = [OUR_TEAM.slice(0, 5), OUR_TEAM.slice(5)];
  return (
    <>
      {memberList.map((list, idx) => {
        return (
          <SubContainer key={idx}>
            {list.map((member, index) => {
              return (
                <TeamItem key={`team${idx}-${index}`}>
                  <ImgWrapper>
                    <Img src={member.img} alt="member.jpg" />
                  </ImgWrapper>
                  <Info>
                    <Name>{member.name}</Name>
                    <Role>{member.role}</Role>
                  </Info>
                </TeamItem>
              );
            })}
          </SubContainer>
        );
      })}
    </>
  );
};

export default OurTeam;
