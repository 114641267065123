import React from "react";
import PageTemplate from "@/components/ui/PageTemplate";
import { MISSION_CONTEXT } from "@/constants/context";
import {
  Layout,
  DescriptionWrapper,
  MainDescription,
  SubDescription,
  ImgWrapper,
  Img
} from "./missionStyle";
import useObserver from "@/hooks/page/useObserver";

const Mission = () => {
  useObserver("content-Mission");

  return (
    <PageTemplate id="Mission & Vision" backgroundColor={"#ffffff"}>
      <Layout id="content-Mission">
        <DescriptionWrapper>
          <MainDescription>{MISSION_CONTEXT.MAIN}</MainDescription>
          {MISSION_CONTEXT.SUB.map((item, index) => {
            return <SubDescription key={index}>{item}</SubDescription>;
          })}
        </DescriptionWrapper>
        <ImgWrapper>
          <Img src="/img/main-top-bg.png" alt="main-top-copy" />
        </ImgWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Mission);
