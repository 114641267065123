import React, { useEffect, useState } from "react";
import useWindowSize from "@/hooks/page/useWindowSize";
import Articles from "@/components/news/Articles";
import {
  NewsPageTemplate,
  Layout,
  NewsPageTitleWrapper,
  NewsPageTitle
} from "./newsStyle";
import useObserver from "@/hooks/page/useObserver";

const News = () => {
  const { width, height } = useWindowSize();

  useObserver("content-News");

  return (
    <NewsPageTemplate
      id="Press & Article"
      $width={width}
      $height={height}
      $backgroundcolor={"#ffffff"}
    >
      <Layout id="content-News">
        <NewsPageTitleWrapper>
          <NewsPageTitle>
            How <strong>SPACEMAP</strong> is recognized
          </NewsPageTitle>
        </NewsPageTitleWrapper>
        <Articles />
      </Layout>
    </NewsPageTemplate>
  );
};

export default React.memo(News);
