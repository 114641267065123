import React from "react";
import PageTemplate, {
  SubTitle,
  Emphasize,
  TitleWrapper,
  Content,
  Description
} from "@/components/ui/PageTemplate";
import {
  Layout,
  AstroOrcaImg,
  AstroTitle,
  DescriptionWrapper,
  VideoWrapper,
  Video
} from "./astroOrcaStyle";
import PlatformLink from "@/components/ui/button/PlatformLink";
import useObserver from "@/hooks/page/useObserver";

const AstroOrca = () => {
  useObserver("content-AstroOrca");

  return (
    <PageTemplate id="AstroOrca">
      <Layout id="content-AstroOrca">
        <TitleWrapper>
          <AstroOrcaImg src="/services/astroOrca.png" alt="astroOrca" />
          <AstroTitle>
            <strong>
              Astro<Emphasize>O</Emphasize>rca
            </strong>
            ,<br />
            <SubTitle>
              the real-time solver for space optimization problems
            </SubTitle>
          </AstroTitle>
        </TitleWrapper>
        <Content>
          <DescriptionWrapper>
            <Description>
              Space investment will be sustainable only when it creates profit.
              Profit can be materialized by optimizing the operations of
              expensive space assets in extreme environment. This requires
              software which comprehends spatiotemporal, i.e., space-time,
              dynamics of the proximity among fast-moving spacecrafts. This type
              of optimization problems belongs to computationally hard problems,
              i.e., NP-hard.
            </Description>
            <Description>
              A-<Emphasize>O</Emphasize> features optimizations including data
              transmission in multi-orbits, launch optimization, shuttle
              logistics for space gas stations, ADR itinerary, reentry, etc. New
              Space applications need A-<Emphasize>O</Emphasize> for many
              optimization problems yet to be found.
            </Description>
          </DescriptionWrapper>
          <VideoWrapper>
            <Video muted autoPlay loop>
              <source src="/video/AstroOrca.mp4" type="video/mp4" />
            </Video>
          </VideoWrapper>
        </Content>
        <PlatformLink />
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(AstroOrca);
