import styled from "styled-components";

const Logo = () => {
  const onClick = () => {
    const page = document.getElementById("Home");

    if (page) {
      page.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <LogoImg alt="spacemap" src="/logo/maintype_3.png" onClick={onClick} />
  );
};

export default Logo;

const LogoImg = styled.img`
  width: 13rem;
  cursor: pointer;
  pointer-events: auto;
`;
