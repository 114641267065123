import React from "react";
import PageTemplate, {
  TitleWrapper,
  Emphasize,
  Description,
  SubTitle
} from "@/components/ui/PageTemplate";
import {
  Layout,
  AstroTitle,
  DescriptionWrapper,
  AstroLibraryContent,
  ImgWrapper,
  Img
} from "./astroLibraryStyle";
import useObserver from "@/hooks/page/useObserver";

const AstroLibrary = () => {
  useObserver("content-AstroLibrary");

  return (
    <PageTemplate id="AstroLibrary">
      <Layout id="content-AstroLibrary">
        <TitleWrapper>
          <AstroTitle>
            <strong>
              Astro<Emphasize>L</Emphasize>ibrary
            </strong>
            , <br />
            <SubTitle>
              the toolkit for near real-time development of real-time space
              software
            </SubTitle>
          </AstroTitle>
        </TitleWrapper>
        <AstroLibraryContent>
          <ImgWrapper>
            <Img src="/services/astroLibrary.png" />
          </ImgWrapper>
          <DescriptionWrapper>
            <Description>
              Good solutions of the optimization problems among fast-moving
              space objects cannot be efficiently produced using traditional
              algorithms. We are introducing a paradigm-transforming real-time
              technology targeting for the catalogue size of O(10<sup>6</sup>)
              or more.
            </Description>
            <Description>
              A-<Emphasize>L</Emphasize> has RESTful APIs of the powerful
              SpaceMap functions with the Python interfaces which wraps the C++
              implementation of the Space-Time AI (ST-AI) algorithms.
              Application programmers can simply embed the APIs to quickly
              develop real-time application programs for space-time reasoning
              problems among many satellites.
            </Description>
          </DescriptionWrapper>
        </AstroLibraryContent>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(AstroLibrary);
