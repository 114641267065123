import PageTemplate, {
  Description,
  SubTitle
} from "@/components/ui/PageTemplate";
import {
  SpaceScannerTitle,
  SpaceScannerTitleWrapper,
  DescriptionWrapper,
  Layout,
  Video,
  VideoWrapper,
  SpaceScannerContent
} from "./SpaceScannerStyle";
import useObserver from "@/hooks/page/useObserver";
import { SPACESCANNER_CONTEXT } from "@/constants/context";
import React from "react";

const SpaceScanner = () => {
  const VIDEO_URL =
    "https://spacemap-an2-s3.s3.ap-northeast-2.amazonaws.com/SpaceScanner.mp4";

  useObserver("content-SpaceScanner");

  return (
    <PageTemplate id="SpaceScanner">
      <Layout id="content-SpaceScanner">
        <SpaceScannerTitleWrapper>
          <SpaceScannerTitle>
            <strong>SpaceScanner</strong>,
            <br />
            <SubTitle>{SPACESCANNER_CONTEXT.SUBTITLE}</SubTitle>
          </SpaceScannerTitle>
        </SpaceScannerTitleWrapper>
        <SpaceScannerContent>
          <VideoWrapper>
            <Video playsInline muted autoPlay loop>
              <source src={VIDEO_URL} />
            </Video>
          </VideoWrapper>
          <DescriptionWrapper>
            <Description>{SPACESCANNER_CONTEXT.DESCRIPTION}</Description>
          </DescriptionWrapper>
        </SpaceScannerContent>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(SpaceScanner);
