import { EVENT_CALENDAR_URL } from "./calendar";

const NUM_PAGE = 12;

const MENU = ["Product", "Technology", "News", "Company"];

const SUBMENU = {
  Product: ["42Talks", "Astro-1", "AstroOrca", "AstroLibrary", "SpaceScanner"],
  Technology: ["Space-Time AI (ST-AI)", "Voronoi Diagram", "Resources"],
  News: ["Press & Article"],
  Company: ["About", "Mission & Vision", "Team", "Contact"]
};

const PRODUCT_LINKS = [
  {
    key: "Platform",
    url: "https://platform.spacemap42.com/"
  },
  {
    key: "Space Calendar",
    url: EVENT_CALENDAR_URL
  }
];

export { NUM_PAGE, MENU, SUBMENU, PRODUCT_LINKS };
