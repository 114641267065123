import React from "react";
import PageTemplate, {
  TitleWrapper,
  Description
} from "@/components/ui/PageTemplate";
import {
  Layout,
  Header,
  Container,
  DescriptionWrapper
} from "./resourcesStyle";
import { RESOURCE_CONTEXT } from "@/constants/context";
import ResourceList from "@/components/resource/ResourceList";
import useObserver from "@/hooks/page/useObserver";
import { Flex } from "@chakra-ui/react";

const Resources = () => {
  useObserver("content-Resources");

  return (
    <PageTemplate id="Resources">
      <Layout id="content-Resources">
        <Flex h={"100%"} flexDirection={"column"} gap={"5rem"}>
          <TitleWrapper>
            <Header> {RESOURCE_CONTEXT.TITLE} </Header>
          </TitleWrapper>
          <DescriptionWrapper>
            {RESOURCE_CONTEXT.DESCRIPTION.map((item, index) => {
              return <Description key={index}>{item}</Description>;
            })}
          </DescriptionWrapper>
        </Flex>
        <Container>
          <ResourceList />
        </Container>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Resources);
