import React, { useState, useEffect, useRef } from "react";
import PlatformLink from "@/components/ui/button/PlatformLink";
import { ASTRO1_CONTEXT } from "@/constants/context";
import PageTemplate, {
  SubTitle,
  TitleWrapper,
  Description,
  Emphasize
} from "@/components/ui/PageTemplate";
import {
  Layout,
  Video,
  ContentWrapper,
  AstroTitle,
  ConjunctionWrapper,
  DescriptionWrapper
} from "./astro1Style";
import useObserver from "@/hooks/page/useObserver";

const Astro1 = () => {
  const videoRef = useRef(null);

  useObserver("content-Astro1");

  return (
    <PageTemplate id="Astro-1">
      <Layout id="content-Astro1">
        <Video ref={videoRef} muted autoPlay loop>
          <source src="/video/AstroOne.mp4" type="video/mp4" />
        </Video>
        <ContentWrapper>
          <DescriptionWrapper>
            <TitleWrapper>
              <AstroTitle>
                <strong>
                  Astro-<Emphasize>1</Emphasize>
                </strong>
                ,<br />
                <SubTitle>the real-time guardian for space safety</SubTitle>
              </AstroTitle>
            </TitleWrapper>
            {ASTRO1_CONTEXT.DESCRIPTION.map((item, index) => {
              return <Description key={index}>{item}</Description>;
            })}
            <PlatformLink />
          </DescriptionWrapper>
        </ContentWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Astro1);
