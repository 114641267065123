import React from "react";
import PageTemplate from "@/components/ui/PageTemplate";
import { Layout, Title } from "./teamStyle";
import OurTeam from "@/components/company/OurTeam";
import Advisors from "@/components/company/Advisors";
import useObserver from "@/hooks/page/useObserver";

const Team = () => {
  useObserver("content-Team");

  return (
    <PageTemplate id="Team" backgroundColor={"#ffffff"}>
      <Layout id="content-Team">
        <Title>Our Team</Title>
        <OurTeam />
        <Title>Our Advisors</Title>
        <Advisors />
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(Team);
