import React from "react";
import PageTemplate from "@/components/ui/PageTemplate";
import { ABOUT_CONTEXT } from "@/constants/context";
import {
  Layout,
  ImgWrapper,
  Img,
  DescriptionWrapper,
  MainDescription,
  SubDescription
} from "./aboutStyle";
import useObserver from "@/hooks/page/useObserver";

const About = () => {
  useObserver("content-About");

  return (
    <PageTemplate id="About" backgroundColor={"#ffffff"}>
      <Layout id="content-About">
        <ImgWrapper>
          <Img src="/img/About.png" alt="About" />
        </ImgWrapper>
        <DescriptionWrapper>
          <MainDescription>{ABOUT_CONTEXT.MAIN}</MainDescription>
          {ABOUT_CONTEXT.SUB.map((item, index) => {
            return <SubDescription key={index}>{item}</SubDescription>;
          })}
        </DescriptionWrapper>
      </Layout>
    </PageTemplate>
  );
};

export default React.memo(About);
