import styled from "styled-components";
import {
  Content,
  PageLayout,
  Title,
  TitleWrapper
} from "@/components/ui/PageTemplate";

const Layout = styled(PageLayout)`
  flex-direction: column;
  gap: 2rem;
`;

const SpaceScannerTitle = styled(Title)`
  width: auto;
  line-height: 2rem;
`;

const SpaceScannerTitleWrapper = styled(TitleWrapper)`
  width: 100%;
`;

const DescriptionWrapper = styled.section`
  width: 40%;

  display: flex;
  justify-content: center;

  gap: 1rem;
`;

const VideoWrapper = styled.section`
  width: 40rem;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
`;

const Video = styled.video`
  width: 100%;
  pointer-events: none;
  place-self: end;
`;

const SpaceScannerContent = styled(Content)``;

export {
  Layout,
  SpaceScannerTitle,
  SpaceScannerTitleWrapper,
  DescriptionWrapper,
  Video,
  VideoWrapper,
  SpaceScannerContent
};
